// extracted by mini-css-extract-plugin
export var column = "BlogPostCategoryNew__column__H5r4w";
export var container = "BlogPostCategoryNew__container__N4eES";
export var flex = "BlogPostCategoryNew__flex__iknrH";
export var flexColumn = "BlogPostCategoryNew__flexColumn__YmvuK";
export var gap1 = "BlogPostCategoryNew__gap1__tWdHO";
export var gap2 = "BlogPostCategoryNew__gap2__UQUNb";
export var gap3 = "BlogPostCategoryNew__gap3__kWstJ";
export var gap4 = "BlogPostCategoryNew__gap4__q6Pcc";
export var gap5 = "BlogPostCategoryNew__gap5__AbDw9";
export var pageTitle = "BlogPostCategoryNew__pageTitle__pc3eC";
export var row = "BlogPostCategoryNew__row__fcH9v";